// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import { Button, CardContent, Icon, TableFooter, Typography } from "@mui/material";
import MDButton from "components/MDButton";
import { Link, useNavigate } from "react-router-dom";

//Table :

import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { GET_PRODUCT_API } from "assets/api/productManagementApi";
import CUPagination from "components/CUPagination";
import CUDialog from "components/CUDialog";
import { useMaterialUIController } from "context";
import { DELETE_PRODUCT_API } from "assets/api/productManagementApi";
import { toast } from "react-toastify";
import validationMessage from "utils/validationMessage";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

function toInch(cm) {
  return (cm / 2.54).toFixed(2);
}

function toInchRange(from, to) {
  return `${toInch(from)} - ${toInch(to)}`;
}

function ProductManagements() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  // const { columns, rows } = authorsTableData();
  const { columns: pColumns, rows: pRows } = projectsTableData();
  const navigate = useNavigate();
  toast.configure({
    autoClose: 17000,
    draggable: true,
  });

  // function to create a products
  const add = () => {
    console.log("clicked");
    navigate("/create-product");
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const [apiCalled, setApiCalled] = useState(false);
  const [productList, setProductList] = useState([]);
  const [page, setPage] = useState({
    currentPage: 1,
    dataShowLimit: 10,
    numberOfPages: 0
  });

  async function getProductData() {
    const tokenString = localStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    const token = userToken?.token;
    if (token) {
      const searchData = {
        page: page.currentPage, 
        limit: page.dataShowLimit 
      };
      const productListData = await GET_PRODUCT_API(searchData);
      if(productListData.status === 200) {
        setProductList(productListData.data);
        let totalCount = productListData.count;
        if(totalCount > 0) {
          let numberOfPages = Math.ceil(totalCount / page.dataShowLimit);
          setPage((p) => { return { ...page, numberOfPages: numberOfPages } });
        }
        setApiCalled(true);
      } else {
        setProductList([]);
        setApiCalled(true);
      }
    } else {
      toast.error(validationMessage.PLEASE_LOGIN);
      setApiCalled(true);
    }
  }

  useEffect(()=> {
    setApiCalled(false);
    getProductData();
  }, [ page.currentPage ]);

  const handlePaginationChange = (e, p) => {
    setPage({ ...page, currentPage: p });
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [deleteProduct, setDeleteProduct] = useState();
  const handleClickDialogOpen = (productId) => {
    console.log("Data-->>", productId);
    setDeleteProduct(productId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDeleteProduct = async (productId) => {
    if(productId) {
      const tokenString = localStorage.getItem("token");
      const userToken = JSON.parse(tokenString);
      const token = userToken?.token;
      if (token) {
        const apiResponse = await DELETE_PRODUCT_API(token, productId);
        if(apiResponse?.status === 200) {
          toast.success(apiResponse.message);
          setOpenDialog(false);
          // navigate("/product-management");
          // window.location.reload();
          getProductData();
        } else {
          if(apiResponse && apiResponse.message) {
            toast.error(apiResponse.message);
          } else {
            toast.error(`${apiResponse?.errors[0]?.path.replaceAll("_", " ")} ${apiResponse?.errors[0]?.msg}`);
          }
        }
      } else {
        toast.error(validationMessage.PLEASE_LOGIN);
      }
    }
  }

 
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                pt={2}
                px={2}
                display="flex"
                variant="gradient"
                borderRadius="lg"
                coloredShadow="info"
                bgColor="info"
                justifyContent="space-between"
                alignItems="center"
                paddingBottom="15px"
              >
                <MDTypography variant="h6" color="white">
                  Manage Products
                </MDTypography>
                {/* <Button variant="contained" onClick={add}>Create a New User</Button> */}
                <MDButton variant="gradient" color="dark" onClick={add}>
                  <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                  &nbsp;Create a New Product
                </MDButton>
              </MDBox>

              <MDBox pt={3}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 450 }} aria-label="simple table">
                    <TableHead>
                      <StyledTableRow className="table-data">
                        <StyledTableCell>Product Name (English)</StyledTableCell>
                        <StyledTableCell>Product Name (Arabic)</StyledTableCell>
                        <StyledTableCell>Product Image</StyledTableCell>
                        <StyledTableCell>Price sq.cm(SAR)</StyledTableCell>
                        <StyledTableCell align="center">Actions</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                    {!apiCalled && 
                      <StyledTableRow>
                        <TableCell colSpan="9">
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",  // Ensures the Box takes full width of the TableCell
                              height: "100%", // Ensures the Box takes full height of the TableCell
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        </TableCell>
                      </StyledTableRow>
                    }
                    {apiCalled && ((productList && productList.length > 0) ?
                      productList.map((row) => (
                        <StyledTableRow
                          key={row._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell component="td" scope="row" className="common-25">
                            {row?.product_name?.en}
                          </StyledTableCell>
                          <StyledTableCell component="td" scope="row" className="common-25">
                            {row?.product_name?.ar}
                          </StyledTableCell>
                          <StyledTableCell component="td" scope="row" className="common-25">
                            <img src={row.product_picture ? row.product_picture : ""} alt={row.product_actual_name} style={{width: '3rem', height: '3rem'}} />
                          </StyledTableCell>
                          <StyledTableCell component="td" scope="row" className="common-25">
                            {row?.currency} {row.product_price_per_centimeter_square}
                          </StyledTableCell>
                          <StyledTableCell align="left" className="common-25">
                            <CUDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog} deleteId={deleteProduct} handleDelete={handleDeleteProduct} headingMessage="Confirmation" bodyMessage="Are you sure want to delete ?" />
                            {/* <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}> */}
                              <MDBox display="flex" alignItems="center" sx={{ ml: "5%" }}>
                                <Link to={`/product/edit/${row._id}`} preventScrollReset={true}>
                                  <MDButton variant="text" color={darkMode ? "white" : "dark"}>
                                    <Icon>edit</Icon>&nbsp;edit
                                  </MDButton>
                                </Link>
                                <MDButton variant="text" color="error" onClick={() => handleClickDialogOpen(row._id)}>
                                  <Icon>delete</Icon>&nbsp;delete
                                </MDButton>
                              </MDBox>
                            {/* </MDBox> */}
                          </StyledTableCell>
                        </StyledTableRow>
                      )) :
                        <StyledTableRow
                          key="1"
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell colSpan="9">
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",  // Ensures the Box takes full width of the TableCell
                                height: "100%", // Ensures the Box takes full height of the TableCell
                              }}
                            >
                              No Product Found
                            </Box>
                          </TableCell>
                        </StyledTableRow>
                      )
                    }
                    </TableBody>
                    <TableFooter>
                      <CUPagination numberOfPages = {page.numberOfPages} currentPage = {page.currentPage} onChange = {handlePaginationChange}/>
                    </TableFooter>
                  </Table>
                </TableContainer>

                {/* <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                /> */}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ProductManagements;
