// List of all API Endpoints

// For Admin
export const API_ADMIN_LOGIN = "/admin/login";
export const API_ADMIN_LOGOUT = "/admin/logout";
export const API_ADMIN_LIST = "/admin/get-all";
export const API_USER_LIST = "/admin/get-all-app-users";
export const API_ADMIN_CREATE = "/admin/add";
export const API_ADMIN_UPDATE = "/admin/update-admin-details";
export const API_ADMIN_DELETE = "/admin/delete-admin";
export const API_ADMIN_VIEW = "/admin/get";
export const API_ADMIN_CHANGE_PASSWORD = "/admin/change-password";
export const ADMIN_DASHBOARD_DATA = "/admin/dashboard"
export const API_USER_EXPORT = "/admin/user-export";

// For Product
export const API_PRODUCT_LIST = "/product";
export const API_PRODUCT_ADD = "/product";
export const API_PRODUCT_VIEW = "/product";
export const API_PRODUCT_UPDATE = "/product";
export const API_PRODUCT_DELETE = "/product";

// For Coupon
export const API_COUPON_LIST = "/coupon";
export const API_COUPON_ADD = "/coupon";
export const API_COUPON_VIEW = "/coupon";
export const API_COUPON_UPDATE = "/coupon";
export const API_COUPON_DELETE = "/coupon";

// For Orders
export const API_ORDERS_LIST = "/checkout/order-list"; 
export const API_ORDERS_VIEW_LIST = "/checkout/order-view";
export const API_EXPORT_ORDER_LIST = "/checkout/export-order-list"; 

// For Custom Frame Size
export const API_CUSTOM_FRAME_SIZE_LIST = "/custom-frame-size";
export const API_CUSTOM_FRAME_SIZE_ADD = "/custom-frame-size";
export const API_CUSTOM_FRAME_SIZE_VIEW = "/custom-frame-size/{id}";
export const API_CUSTOM_FRAME_SIZE_UPDATE = "/custom-frame-size/{id}";
export const API_CUSTOM_FRAME_SIZE_DELETE = "/custom-frame-size/{id}";


export const actualApiUrl = (apiUrl) => {
    return `${process.env.REACT_APP_API_BASE_URL}${apiUrl}`
}

// Get Offset Value
export function getOffSetValue() {
    // Get the timezone offset in minutes
    const offsetInMinutes = new Date().getTimezoneOffset();
    // Determine the sign of the offset (e.g., 330 or -330)
    const offsetSign = offsetInMinutes >= 0 ? '-' : '';
    // Get offset value
    const offsetValue = `${offsetSign}${Math.abs(offsetInMinutes)}`;

    return offsetValue;
}